.projectsContainer {
  text-align: center;
}

.projectsContainerGrey {
  margin: auto;
  text-align: center;
  background-color: #e1e1e1;
}

.row {
  padding-left: 0vw;
  flex: 1;
  flex-direction: row;
  display: flex;
  height: 100%;
}

.rowReverse {
  padding-left: 0vw;
  flex-direction: row-reverse;
  display: flex;
  height: 100%;
}

.primaryTitleDesktop {
  margin-top: auto;
  margin-bottom: auto;
  text-align: left;
  font-size: 2vh;
  color: black;
  font-family: Avenir-Black;
  font-weight: bold;
  margin-left: 10vw;
}

.primaryTitleMobile {
  margin-top: auto;
  margin-bottom: auto;
  text-align: left;
  font-size: 1.5vh;
  color: black;
  font-family: Avenir-Black;
  font-weight: bold;
  margin-left: 10vw;
}

.secondaryTitleDesktop {
  margin-top: auto;
  margin-bottom: auto;
  text-align: left;
  font-size: 7vh;
  color: black;
  font-family: Butler;
  letter-spacing: 0.2vh;
  margin-left: 10vw;
  width: 50%;
}

.secondaryTitleMobile {
  margin-top: auto;
  margin-bottom: auto;
  text-align: left;
  font-size: 2.1vh;
  color: black;
  font-family: Butler;
  letter-spacing: 0.2vh;
  margin-left: 10vw;
  width: 50%;
}

.imgProjects {
  position: absolute;
  width: 40.4%;
  height: auto;
  left: 0;
}

.imgProjectsMobile {
  position: absolute;
  width: 53.4%;
  height: auto;
  left: 0;
}

.imgProjectsReverse {
  position: absolute;
  width: 45%;
  height: auto;
  right: 0;
}

.imgProjectsReverseMobile {
  position: absolute;
  width: 55.4%;
  height: auto;
  right: 0;
}

.containerTitleProjects {
  margin-left: -25vw;
  width: 40%;
}

.containerTitleProjectsReverse {
  margin-top: -6vw;
  margin-left: 10vw;
  width: 40%;
}

.titleProjectsDesktop {
  margin-top: 6vw;
  margin-left: -2vw;
  text-align: left;
  font-size: 3vh;
  color: black;
  font-family: Avenir-Black;
  font-weight: bold;
}

.titleProjectsMobile {
  margin-top: 6vw;
  margin-left: 8vw;
  text-align: left;
  font-size: 1.1vh;
  color: black;
  font-family: Avenir-Black;
  font-weight: bold;
}

.logo {
  margin-top: 3.5vw;
  margin-bottom: 1.5vw;
  width: 5vw;
}

.logoReverse {
  margin-top: 3.5vw;
  margin-bottom: 1.5vw;
  width: 15vw;
}

.descriptionDesktop {
  margin-top: "auto";
  margin-left: "auto";
  text-align: left;
  font-size: 2.3vh;
  color: darkgrey;
  font-family: Avenir-Book;
  font-weight: 400;
}

.descriptionMobile {
  margin-top: "auto";
  margin-left: "auto";
  text-align: left;
  font-size: 1vh;
  color: darkgrey;
  font-family: Avenir-Book;
  font-weight: 400;
}
