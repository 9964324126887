.mainHeaderBar {
  height: 9vh;
}

.flexRow {
  padding-left: 10%;
  flex: 1;
  flex-direction: row;
  display: flex;
  height: 100%;
}

.logo {
  margin-top: auto;
  margin-bottom: auto;
  background-color: green;
  color: white;
}

.brandNameHeader {
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 1.4vh;
  font-size: 2.2vh;
  color: white;
  font-family: Avenir-Book;
  font-weight: 600;
  letter-spacing: 0.8vh;
}

.hireHeader {
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 1.4vh;
}

.contactHeader {
  position: absolute;
  right: 15vw;
  top: 3.7vh;
  font-size: 1.5vh;
  color: white;
  font-family: Avenir-Book;
  letter-spacing: 0.1vh;
}
